<template>
  <div class="pd-20">
    <a-page-header class="pd-0 mb-10">
      <div slot="title">
        <h1>
          <img src="@/assets/images/agreement.png" alt="img" />
          Relatório de Produtividade
        </h1>
      </div>
      <div slot="extra"></div>
    </a-page-header>

    <a-row type="flex" :gutter="20">
      <a-col :span="24">
        <a-row :gutter="[20, 20]">
          <a-col :span="$root.windowWidth > 961 ? 8 : 24">
            <div class="filter-box">
              <a-row>
                <a-col :span="24">
                  <div class="field">
                    <font class="label">Período</font>
                    <a-range-picker
                      v-model="contract.filters.period.selected"
                      :format="['DD/MM/YYYY']"
                      separator="a"
                      :value-format="'YYYY-MM-DD'"
                      :disabled="contract.loading ? true : false"
                    />
                  </div>
                </a-col>

                <a-col class="mt-20" :span="24">
                  <div class="field">
                    <font class="label">Tipo de venda</font>

                    <a-checkbox-group
                      style="width: 100%"
                      v-model="contract.filters.saleType.selected"
                      :disabled="contract.loading ? true : false"
                    >
                      <a-checkbox
                        v-for="(product, index) in contract.filters.saleType
                          .list"
                        :key="index"
                        :value="product.value"
                        :disabled="product.disabled"
                      >
                        {{ product.label }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-col>

                <a-col class="mt-20" :span="24">
                  <div class="field">
                    <font class="label">Produtos</font>
                    <a-checkbox-group
                      style="width: 100%"
                      v-model="contract.filters.productsCategory.selected"
                      :disabled="contract.loading ? true : false"
                    >
                      <a-checkbox
                        v-for="(product, index) in products"
                        :key="index"
                        :value="product.value"
                        :disabled="product.disabled"
                      >
                        {{ product.label }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-col>

                <a-col
                  v-if="
                    contract.filters.productsCategory.selected.includes(
                      'flight'
                    )
                  "
                  class="mt-20"
                  :span="24"
                >
                  <div class="field">
                    <font class="label">Aéreo</font>

                    <a-checkbox-group
                      style="width: 100%"
                      v-model="contract.filters.flightType.selected"
                      :disabled="contract.loading ? true : false"
                    >
                      <a-checkbox
                        v-for="(product, index) in contract.filters.flightType
                          .list"
                        :key="index"
                        :value="product.value"
                        :disabled="product.disabled"
                      >
                        {{ product.label }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-col>

                <a-col class="mt-20 pt-0" :span="24">
                  <div class="field">
                    <font class="label">Etapa</font>
                    <a-checkbox-group
                      style="width: 100%"
                      v-model="contract.filters.status.selected"
                      :disabled="contract.loading ? true : false"
                    >
                      <a-checkbox
                        v-for="(s, index) in status"
                        :key="index"
                        :value="s.value"
                        :disabled="s.disabled"
                      >
                        {{ s.label }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-col>

                <a-col class="mt-20 pt-0" :span="24">
                  <div class="field">
                    <font class="label">Usuários</font>
                    <a-select
                      class="travel-multiselector travel-input"
                      placeholder="Selecione um ou mais usuários"
                      optionFilterProp="txt"
                      mode="multiple"
                      v-model="contract.filters.users.selected"
                      show-search
                      allowClear
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="(item, index) of contract.filters.users.list"
                        :key="index"
                        :value="item.value"
                        :txt="item.label"
                      >
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </div>
                </a-col>

                <a-col class="mt-30 pt-0 a-center" :span="24">
                  <a-button
                    @click="getContracts()"
                    :loading="contract.loading"
                    type="primary"
                  >
                    Gerar Relatório
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="$root.windowWidth > 961 ? 8 : 24">
            <div class="report-box">
              <h2>
                <img
                  src="@/assets/images/dashboard/contracts/ranking.png"
                  alt="ico"
                  width="20"
                />
                Contratos totais
              </h2>
              <div class="content">
                <div v-if="!contract.loading">
                  <highcharts :options="contractsPie"></highcharts>

                  <a-row
                    v-if="contract.list.length > 0"
                    class="legend mb-10"
                    type="flex"
                    justify="space-between"
                    st
                  >
                    <a-col>
                      <strong>Total:</strong>
                      {{ contract.meta.total ? contract.meta.total : 0 }}
                      contratos
                    </a-col>

                    <a-col :span="13">
                      <strong>Total Geral:</strong> R$
                      {{
                        contract.meta.total_sold ? contract.meta.total_sold : 0
                      }}
                      <br />

                      <strong>Total de vendas:</strong> R$
                      {{
                        contract.meta.total_without_payment_card
                          ? contract.meta.total_without_payment_card
                          : 0
                      }}
                      <br />
                    </a-col>
                  </a-row>
                </div>
                <div v-else>
                  <a-skeleton active />
                  <a-skeleton active />
                  <a-skeleton active />
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="$root.windowWidth > 961 ? 8 : 24">
            <div class="report-box">
              <h2>
                <img
                  src="@/assets/images/dashboard/contracts/ranking.png"
                  alt="ico"
                  width="20"
                />
                Lucratividade
              </h2>
              <div class="content">
                <div v-if="!contract.loading">
                  <highcharts :options="contractsProfitPie"></highcharts>

                  <a-row
                    v-if="contract.list.length > 0"
                    class="legend mb-10"
                    type="flex"
                    justify="space-between"
                    st
                  >
                    <a-col :span="13">
                      <strong>Lucratividade:</strong> R$
                      {{ contract.meta.total_profit }}
                    </a-col>
                  </a-row>
                </div>
                <div v-else>
                  <a-skeleton active />
                  <a-skeleton active />
                  <a-skeleton active />
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="24">
            <div class="report-box">
              <h2>
                <img
                  src="@/assets/images/dashboard/contracts/ranking.png"
                  alt="ico"
                  width="20"
                />
                Ranking dinâmico de produtividade (usuários)
              </h2>
              <div class="content" style="min-height: 200px">
                <a-table
                  class="chart-table travel-table"
                  :columns="RankingColumns"
                  :data-source="rankingTable.data"
                  :loading="contract.loading"
                  :pagination="false"
                >
                  <template slot="totalContracts" slot-scope="record">
                    {{ record.totalContracts }}
                  </template>

                  <template slot="percentage" slot-scope="record">
                    {{
                      formatPercentage(
                        (100 * record.totalContracts) /
                          rankingTable.total[0].totalContracts
                      )
                    }}
                  </template>

                  <template slot="consultant" slot-scope="record">
                    <a-avatar
                      class="mr-5"
                      :src="record.consultant.avatar"
                      :size="30"
                    />
                    {{ record.consultant.name }}
                  </template>

                  <template slot="hotelTotal" slot-scope="record">
                    {{ formatPricePtBr(record.hotel.total) }}
                  </template>

                  <template slot="hotelNet" slot-scope="record">
                    {{ formatPricePtBr(record.hotel.totalNet) }}
                  </template>

                  <template slot="flightTotal" slot-scope="record">
                    {{ formatPricePtBr(record.flight.total) }}
                  </template>

                  <template slot="flightNet" slot-scope="record">
                    {{ formatPricePtBr(record.flight.totalNet) }}
                  </template>

                  <template slot="serviceTotal" slot-scope="record">
                    {{ formatPricePtBr(record.service.total) }}
                  </template>

                  <template slot="serviceNet" slot-scope="record">
                    {{ formatPricePtBr(record.service.totalNet) }}
                  </template>

                  <template slot="packageTotal" slot-scope="record">
                    {{ formatPricePtBr(record.package.total) }}
                  </template>

                  <template slot="packageNet" slot-scope="record">
                    {{ formatPricePtBr(record.package.totalNet) }}
                  </template>

                  <template slot="financialCosts" slot-scope="record">
                    {{ formatPricePtBr(record.financialCosts.total) }}
                  </template>

                  <template slot="totalProfit" slot-scope="record">
                    <strong class="cgreen" style="font-weight: 600 !important">
                      {{ formatPricePtBr(record.contractData.totalProfit) }}
                    </strong>
                  </template>

                  <template slot="totalValue" slot-scope="record">
                    <strong style="font-weight: 600 !important">
                      {{ formatPricePtBr(record.contractData.total) }}
                    </strong>
                  </template>
                </a-table>

                <a-table
                  v-if="rankingTable.total.length > 0"
                  class="chart-table travel-table mt-0"
                  :columns="RankingTotalsColumns"
                  :data-source="rankingTable.total"
                  :loading="contract.loading"
                  :showHeader="false"
                  :pagination="false"
                  style="font-weight: 600"
                >
                  <template slot="consultant" slot-scope="record">
                    {{ record.consultant }}
                  </template>
                </a-table>
              </div>
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="24">
            <div class="report-box">
              <h2
                @click="
                  openAuditContracts
                    ? (openAuditContracts = false)
                    : (openAuditContracts = true)
                "
                style="cursor: pointer"
              >
                <a-row type="flex" justify="space-between">
                  <a-col>
                    <a-icon type="codepen" /> Auditoria dados dos contratos
                    (Somente Adm)</a-col
                  >
                  <a-col> <a-icon type="caret-down"/></a-col>
                </a-row>
              </h2>
              <div
                v-show="openAuditContracts"
                class="content"
                style="min-height: 200px"
              >
                <json-viewer
                  :value="rankingTable.contractsByConsultant"
                  :show-array-index="false"
                  :expand-depth="2"
                ></json-viewer>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import _ from "lodash";
import { format } from "date-fns";

export default {
  name:"ContractProductivityReport",
  mixins: [contractMixins, formatThings],
  data() {
    return {
      reportsIsGenerated: false,
      openAuditContracts: false,
      rankingTable: {
        data: [],
        total: [],
        contractsByConsultant: [],
      },
      contractsPie: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
        },
        title: {
          text: "",
          align: "center",
          verticalAlign: "middle",
          y: 0,
        },
        tooltip: {
          formatter: function() {
            return `<b>${this.point.consultant.name}<br>
                        ${this.point.percentag}%<br>
                        ${this.point.totalContracts} Contratos<br>
                        ${this.point.contractData.formatedTotal}</b>`;
          },
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              distance: -50,
              style: {
                fontWeight: "bold",
                color: "white",
              },
            },
            size: "70%",
          },
        },
        series: [
          {
            type: "pie",
            name: "",
            innerSize: "40%",
            allowPointSelect: true,
            showInLegend: false,
            data: [],
          },
        ],
      },
      contractsProfitPie: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
        },
        title: {
          text: "",
          align: "center",
          verticalAlign: "middle",
          y: 0,
        },
        tooltip: {
          formatter: function() {
            return `<b>${this.point.consultant.name}<br>
                        ${this.point.percentag}%<br>
                        ${this.point.contractData.formatedProfit}</b>`;
          },
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              distance: -50,
              style: {
                fontWeight: "bold",
                color: "white",
              },
            },
            size: "70%",
          },
        },
        series: [
          {
            type: "pie",
            name: "",
            innerSize: "40%",
            allowPointSelect: true,
            showInLegend: false,
            data: [],
          },
        ],
      },
      status: [
        {
          label: "Finalizado",
          value: "concluded",
          disabled: false,
        },
        {
          label: "Aberto",
          value: "edit",
          disabled: false,
        },
        {
          label: "Cancelado",
          value: "canceled",
          disabled: false,
        },
      ],
      products: [
        {
          label: "Hotel",
          value: "hotel",
          disabled: false,
        },
        {
          label: "Aéreo",
          value: "flight",
          disabled: false,
        },
        {
          label: "Serviço",
          value: "service",
          disabled: false,
        },
        {
          label: "Cruzeiro",
          value: "cruise",
          disabled: true,
        },
      ],
      RankingColumns: [
        {
          title: "Consultores",
          scopedSlots: {
            customRender: "consultant",
          },
          width: 120,
        },
        {
          title: "Contratos",
          scopedSlots: {
            customRender: "totalContracts",
          },
          width: 70,
          //  defaultSortOrder: "descend",
          sorter: (a, b) => a.totalContracts - b.totalContracts,
        },
        {
          title: "%",
          scopedSlots: {
            customRender: "percentage",
          },
          width: 60,
          sorter: (a, b) => a.y - b.y,
        },
        {
          title: "Hotéis",
          scopedSlots: {
            customRender: "hotelTotal",
          },
          width: 80,
          sorter: (a, b) => a.hotel.total - b.hotel.total,
        },
        {
          title: "Neto",
          scopedSlots: {
            customRender: "hotelNet",
          },
          width: 80,
          sorter: (a, b) => a.hotel.totalNet - b.hotel.totalNet,
        },
        {
          title: "Aéreo",
          scopedSlots: {
            customRender: "flightTotal",
          },
          width: 80,
          sorter: (a, b) => a.flight.total - b.flight.total,
        },
        {
          title: "Neto",
          scopedSlots: {
            customRender: "flightNet",
          },
          width: 80,
          sorter: (a, b) => a.flight.totalNet - b.flight.totalNet,
        },
        {
          title: "Serviços",
          scopedSlots: {
            customRender: "serviceTotal",
          },
          width: 80,
          sorter: (a, b) => a.service.total - b.service.total,
        },
        {
          title: "Neto",
          scopedSlots: {
            customRender: "serviceNet",
          },
          width: 80,
          sorter: (a, b) => a.service.totalNet - b.service.totalNet,
        },
        {
          title: "Pacotes",
          scopedSlots: {
            customRender: "packageTotal",
          },
          width: 80,
          sorter: (a, b) => a.package.total - b.package.total,
        },
        {
          title: "Neto",
          scopedSlots: {
            customRender: "packageNet",
          },
          width: 80,
          sorter: (a, b) => a.package.totalNet - b.package.totalNet,
        },
        {
          title: "C.F",
          scopedSlots: {
            customRender: "financialCosts",
          },
          width: 80,
          sorter: (a, b) => a.financialCosts.total - b.financialCosts.total,
        },
        {
          title: "Lucratividade",
          scopedSlots: {
            customRender: "totalProfit",
          },
          sorter: (a, b) =>
            a.contractData.totalProfit - b.contractData.totalProfit,
          width: 80,
        },
        {
          title: "Total",
          scopedSlots: {
            customRender: "totalValue",
          },
          width: 80,
          sorter: (a, b) => a.contractData.total - b.contractData.total,
        },
      ],
      RankingTotalsColumns: [
        {
          title: "Consultores",
          scopedSlots: {
            customRender: "consultant",
          },
          width: 120,
        },
        {
          title: "Contratos",
          dataIndex: "totalContracts",
          width: 70,
        },
        {
          title: "%",
          dataIndex: "percentage",
          width: 60,
        },
        {
          title: "Hotéis",
          dataIndex: "hotelTotal",
          width: 80,
        },
        {
          title: "Neto",
          dataIndex: "hotelNet",
          width: 80,
        },
        {
          title: "Aéreo",
          dataIndex: "flightTotal",
          width: 80,
        },
        {
          title: "Neto",
          dataIndex: "flightNet",
          width: 80,
        },
        {
          title: "Serviços",
          dataIndex: "serviceTotal",
          width: 80,
        },
        {
          title: "Neto",
          dataIndex: "serviceNet",
          width: 80,
        },
        {
          title: "Pacotes",
          dataIndex: "packageTotal",
          width: 80,
        },
        {
          title: "Neto",
          dataIndex: "packageNet",
          width: 80,
        },
        {
          title: "C.F",
          dataIndex: "financialCosts",
          width: 80,
        },
        {
          title: "Lucratividade n",
          dataIndex: "totalProfit",
          width: 80,
        },
        {
          title: "Total",
          dataIndex: "totalValue",
          width: 80,
        },
      ],
    };
  },
  computed: {
    routeTab() {
      return {
        title: `Relatório de Produtividade - Contratos`,
        icon: "line-chart",
        tips: "",
      };
    },
  },
  mounted() {
    document.title = "Relatório de Produtividade - Contratos";

    this.$http
      .get("/user/list?page=1&per_page=100&user_id=1")
      .then(({ data }) => {
        this.contract.filters.users.list = data.data.map((user) => {
          return {
            label: `${user.id} - ${user.first_name} ${user.last_name}`,
            value: user.id,
          };
        });
      });

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.contract.filters.period.selected = [`${firstDay}`, `${lastDay}`];
    this.contract.filters.status.selected = [`concluded`];

    this.contractUrlDefaults();

    this.contract.pagination.perPage = 300;

    this.getContracts();
  },
  methods: {
    generateContractsPieData(totals) {
      this.contractsPie.series[0].data = totals.contractsPie;
    },
    generateContractsProfitPieData(totals) {
      this.contractsProfitPie.series[0].data = totals.profitPie;
    },
    generateContractReportsData(contractData) {
      let consultantstotalsArr = [],
        consultantsArr = [],
        consultantsGroupedArr = [],
        consultantsGroupedArrKeys = [],
        consultantsGroupedArrValues = [],
        totalsArr = [
          {
            consultant: "",
            totalContracts: 0,
            percentage: 0,
            hotelTotal: 0,
            hotelNet: 0,
            flightTotal: 0,
            flightNet: 0,
            serviceTotal: 0,
            serviceNet: 0,
            packageTotal: 0,
            packageNet: 0,
            financialCosts: 0,
            totalValue: 0,
            totalProfit: 0,
          },
        ],
        profitPie = [],
        contractsPie = [],
        totals = {
          consultant: "",
          totalContracts: 0,
          percentage: "",
          hotelTotal: 0,
          hotelNet: 0,
          flightTotal: 0,
          flightNet: 0,
          serviceTotal: 0,
          serviceNet: 0,
          packageTotal: 0,
          packageNet: 0,
          financialCosts: 0,
          totalValue: 0,
          totalProfit: 0,
        };

      if (contractData.length > 0) {
        console.log("consultantsGroupedArrKeys 0010");
        contractData.forEach((contract) => {
          console.log("consultantsGroupedArrKeys 0010", contract.id);

          this.contractPricingData(contract.meta);

          consultantsArr.push({
            id: contract.id,
            consultant: {
              name: `${
                contract.user.first_name
              } ${contract.user.last_name.substring(0, 1)}.`,
              avatar: contract.user.avatar,
            },
            hotel: this.hotelPricingData(contract.meta),
            flight: this.flightPricingData(contract.meta),
            service: this.servicePricingData(contract.meta),
            package: this.packagePricingData(contract.meta),
            financialCosts: this.financialCostData(contract.meta),
            contractData: this.allContractData,
            contractDataPhpBackend: {
              total: contract.meta.reservation_total_price,
              totalNet: contract.meta.sale_to_pay_value,
              totalProfit: contract.meta.sale_profit_value,
            },
          });
        });

        console.log("consultantsGroupedArrKeys 0020");

        consultantsGroupedArr = _.groupBy(
          consultantsArr,
          (group) => group.consultant.name
        );

        consultantsGroupedArrKeys = Object.keys(consultantsGroupedArr);
        consultantsGroupedArrValues = Object.values(consultantsGroupedArr);

        console.log(
          "consultantsGroupedArrKeys 0030",
          consultantsGroupedArrValues
        );

        this.rankingTable.contractsByConsultant = consultantsGroupedArrValues;

        for (var i = 0; i < consultantsGroupedArrKeys.length; i++) {
          let hotel = { total: 0, totalNet: 0, totalProfit: 0 },
            flight = { total: 0, totalNet: 0, totalProfit: 0 },
            service = { total: 0, totalNet: 0, totalProfit: 0 },
            tpackage = { total: 0, totalNet: 0, totalProfit: 0 },
            contractData = {
              total: 0,
              totalNet: 0,
              totalProfit: 0,
            },
            financialCosts = { total: 0 };

          console.log("consultantsGroupedArrKeys 0040");

          consultantsGroupedArrValues[i].forEach((contract) => {
            console.log("consultantsGroupedArrKeys 0050", contract);

            hotel.total += contract.hotel.total;
            hotel.totalNet += contract.hotel.totalNet;
            hotel.totalProfit += contract.hotel.totalProfit;

            flight.total += contract.flight.total;
            flight.totalNet += contract.flight.totalNet;
            flight.totalProfit += contract.flight.totalProfit;

            console.log("consultantsGroupedArrKeys 0050 1", contract);

            service.total += contract.service.total;
            service.totalNet += contract.service.totalNet;
            service.totalProfit += contract.service.totalProfit;

            console.log("consultantsGroupedArrKeys 0050 2", contract);

            tpackage.total += contract.package.total;
            tpackage.totalNet += contract.package.totalNet;
            tpackage.totalProfit += contract.package.totalProfit;

            console.log("consultantsGroupedArrKeys 0050 3", contract);

            contractData.total += contract.contractData.total;

            console.log("consultantsGroupedArrKeys 0050 4", contract);

            contractData.formatedTotal = this.formatPricePtBr(
              contractData.total
            );

            console.log("consultantsGroupedArrKeys 0052", contract);

            contractData.totalNet += contract.contractData.totalNet;

            contractData.totalProfit += contract.contractData.totalProfit;

            contractData.formatedProfit = this.formatPricePtBr(
              contractData.totalProfit
            );

            financialCosts.total += contract.financialCosts.total;
          });

          console.log("consultantsGroupedArrKeys 0060");

          consultantstotalsArr.push({
            consultant: {
              name: consultantsGroupedArrKeys[i],
              avatar: consultantsGroupedArrValues[i][0].consultant.avatar,
            },
            y: consultantsGroupedArrValues[i].length,
            totalContracts: consultantsGroupedArrValues[i].length,
            hotel,
            flight,
            service,
            package: tpackage,
            financialCosts,
            contractData,
          });
        }

        console.log("consultantsGroupedArrKeys 2.2", consultantstotalsArr);

        if (consultantstotalsArr != undefined) {
          consultantstotalsArr.forEach((entry) => {
            totals.totalContracts += parseInt(entry.totalContracts);
            totals.hotelTotal += entry.hotel.total;
            totals.hotelNet += entry.hotel.totalNet;
            totals.flightTotal += entry.flight.total;
            totals.flightNet += entry.flight.totalNet;
            totals.serviceTotal += entry.service.total;
            totals.serviceNet += entry.service.totalNet;
            totals.packageTotal += entry.package.total;
            totals.packageNet += entry.package.totalNet;
            totals.financialCosts += entry.financialCosts.total;
            totals.totalValue += entry.contractData.total;
            totals.totalProfit += entry.contractData.totalProfit;
          });
        }

        totalsArr[0].consultant = "TOTAL";
        totalsArr[0].totalContracts = totals.totalContracts;
        totalsArr[0].percentage = "100%";
        totalsArr[0].hotelTotal = this.formatPricePtBr(totals.hotelTotal);
        totalsArr[0].hotelNet = this.formatPricePtBr(totals.hotelNet);
        totalsArr[0].flightTotal = this.formatPricePtBr(totals.flightTotal);
        totalsArr[0].flightNet = this.formatPricePtBr(totals.flightTotal);
        totalsArr[0].serviceTotal = this.formatPricePtBr(totals.serviceTotal);
        totalsArr[0].serviceNet = this.formatPricePtBr(totals.serviceNet);
        totalsArr[0].packageTotal = this.formatPricePtBr(totals.packageTotal);
        totalsArr[0].packageNet = this.formatPricePtBr(totals.packageNet);
        totalsArr[0].financialCosts = this.formatPricePtBr(
          totals.financialCosts
        );
        totalsArr[0].totalValue = this.formatPricePtBr(totals.totalValue);
        totalsArr[0].totalProfit = this.formatPricePtBr(totals.totalProfit);

        contractsPie = consultantstotalsArr.map((element) => {
          let totalpercentage =
            (element.totalContracts * 100) / this.contract.meta.total;
          return {
            ...element,
            y: totalpercentage,
            percentag: this.formatPercentage(totalpercentage),
          };
        });

        profitPie = consultantstotalsArr.map((element) => {
          let totalpercentage =
            (element.contractData.totalProfit * 100) /
            this.unformatPrice(this.contract.meta.total_profit);
          return {
            ...element,
            y: totalpercentage,
            percentag: this.formatPercentage(totalpercentage),
          };
        });

        // console.log(
        //     "consultantsGroupedArrKeys 3",
        //     consultantstotalsArr
        // );

        this.generateContractsPieData({
          totalsArr,
          contractsPie,
        });

        this.generateContractsProfitPieData({
          totalsArr,
          profitPie,
        });

        this.rankingTable.data = _.orderBy(
          consultantstotalsArr,
          (entry) => entry.totalContracts,
          "desc"
        );

        this.rankingTable.total = totalsArr;
      } else {
        this.rankingTable.data = [];
        this.rankingTable.total = [];
        this.rankingTable.contractsByConsultant = [];

        this.generateContractsPieData({
          totalsArr,
          contractsPie,
        });

        this.generateContractsProfitPieData({
          totalsArr,
          profitPie,
        });
      }
    },
  },
};
</script>
<style lang="sass">
.chart-table
  td
    padding: 4px 6px !important
    font-size: 11px !important
</style>

<style lang="sass" scoped>
.report-box
  background: #fff
  border-radius: 6px
  box-shadow: 0 0 10px #ddd
  overflow: hidden
  .content
    min-height: 472px
    padding: 10px
    .legend
      font-size: 13px
      font-weight: 500
  h2
    padding: 15px 15px
    margin: 0
    font-weight: 400
    font-size: 12px !important
    color: #FFF !important
    background: #3e4953
.filter-box
  background: #fff
  border-radius: 6px
  box-shadow: 0 0 10px #ddd
  padding: 15px
  .field
    position: relative
    padding-top: 20px
    label
      font-size: 11px
      color: #999
    .label
      position: absolute
      font-size: 10px
      font-weight: 600
      color: #ccc !important
      top: 0
      z-index: 1
    // input
    //   font-size: 11px  !important
    //   color: #999
    // input::placeholder
    //   font-size: 11px  !important
    //   color: #999
    // .ant-calendar-range-picker-separator
    //   color: #ddd !important
    //   font-size: 12px
</style>

<style>
.highcharts-credits {
  display: none;
}
</style>
